<template>
    <div
        class="nebula-image__wrapper"
        :id="pictureComponent ? tempId : id"
    >
        <!--
            If an assetId is passed, check if the image exists with ajax
        -->
        <img
            v-if="assetId && imageExists"
            :alt="alt ? alt : ''"
            :class="getClasses"
            :id="pictureComponent ? id : null"
            :loading="isPrinting ? 'eager' : imgLoading"
            :src="getImageSrc"
            :style="cssProps"
        >
        <!--
            Within the NebulaImage component, the icon is set to `display: none`.
            The icon is styled to be visible within the NebulaThumbnail and is visible on load.
            If an assetId is passed and the server returns a failed response, the icon remains visible.
        -->
        <NebulaIcon
            v-else-if="assetId && !imageExists"
            class="nebula-image__icon"
            :symbolId="iconId"
        />
        <!--
            If no assetId is passed, no ajax call
        -->
        <img
            v-else-if="!assetId"
            :alt="alt ? alt : ''"
            :class="getClasses"
            :id="pictureComponent ? id : null"
            :loading="isPrinting ? 'eager' : imgLoading"
            :sizes="sizes ? sizes : getSizes"
            :src="getImageSrc"
            :srcset="srcset ? srcset : getSrcset"
            :style="cssProps"
        >
    </div>
</template>

<script>

import axios from 'axios';
import themingUtils from '@/utils/theming/themingUtils';
import nebulaIconNames from '@/utils/nebula-icon-names';
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';
import { randomStringId } from '@/utils/randomString';

/* eslint-disable global-require */
export default {
    name: 'NebulaImage',
    mixins: [themingUtils],
    components: {
        NebulaIcon,
    },
    data() {
        return {
            availableIcons: nebulaIconNames(),
            errorCounter: 0,
            imageExists: false,
            isPrinting: false,
            loading: false,
            loaded: false,
        };
    },
    props: {
        alt: {
            type: String,
            default: null,
        },
        assetId: {
            type: String,
            default: null,
        },
        assetType: {
            type: String,
            default: null,
        },
        contained: {
            type: Boolean,
            default: false,
        },
        coreStaticAssetsPath: {
            type: String,
        },
        covered: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: null,
        },
        imageSize: {
            type: String,
            default: 'medium',
        },
        imgLoading: {
            type: String,
            default: 'lazy', // either 'lazy' or 'eager'; always reverts to 'eager' when printing
        },
        pictureComponent: {
            type: Boolean,
            default: false,
        },
        sizes: {
            type: String,
            default: null,
        },
        sizesItems: {
            type: [Object, Array],
            default: null,
        },
        src: {
            type: String,
            default: null,
        },
        srcset: {
            type: String,
            default: null,
        },
        srcsetItems: {
            type: [Object, Array],
            default: null,
        },
        thumbnailUrlOverride: {
            type: String,
        },
    },
    computed: {
        getClasses() {
            return [
                'nebula-image',
                {
                    'nebula-image--contained': this.contained,
                    'nebula-image--covered': this.covered,
                },
            ];
        },
        getImageSrc() {
            // the additional props (thumbnailUrlOverride, coreStaticAssetsPath)
            // are so that NebulaImage is backwards compatible with ThumbnailLoader
            let imageSrc = '';
            if (this.thumbnailUrlOverride) {
                imageSrc = this.thumbnailUrlOverride;
            } else if (this.src) {
                imageSrc = this.src;
            } else if (this.coreStaticAssetsPath) {
                imageSrc = this.coreStaticAssetsPath;
            } else if (this.assetId) {
                imageSrc = `https://d1m0scxbrw6oep.cloudfront.net/thumbnails/${this.assetId}-${this.imageSize}`;
            }
            return imageSrc;
        },
        getSizes() {
            let sizesString = null;
            if (this.sizesItems) {
                const sizesMapped = this.sizesItems.map((item) => `(${item.constraint}) ${item.size},\n\t`);
                sizesString = sizesMapped.join('');
            }

            return sizesString;
        },
        getSrcset() {
            let srcsetString = null;
            if (this.srcsetItems) {
                const srcsetMapped = this.srcsetItems.map((item) => `${item.src} ${item.size},\n\t`);
                srcsetString = srcsetMapped.join('');
            }

            return srcsetString;
        },
        iconId() {
            if (this.assetType) {
                const assetTypeSlug = this.assetType.toLowerCase().replace(/[^a-z]/gi, '-');

                if (this.availableIcons.includes(`asset-${assetTypeSlug}`)) {
                    return `asset-${assetTypeSlug}`;
                }
            }

            return 'asset-document';
        },
        randomStringId() {
            return randomStringId();
        },
        tempEl() {
            return document.getElementById(this.tempId);
        },
        tempId() {
            return `nebula-image-tempid--${this.randomStringId}`;
        },
    },
    methods: {
        onBeforePrint() {
            this.isPrinting = true;
        },
        onAfterPrint() {
            this.isPrinting = false;
        },
        async checkImageUrl() {
            if (this.coreStaticAssetsPath) {
                this.setImageStatus();
                return;
            }

            try {
                const response = await axios
                    .head(`${this.getImageSrc}?cacheline=corshead`)
                    .then((resp) => resp).catch((err) => err);

                if (response.status === 200) this.setImageStatus();
            } catch (err) {
                // TODO: handle errors in this api call.
                // eslint-disable-next-line
                console.log(`Image with asset id of ${this.assetId} has an err of ${err}`);
            }
        },
        removeWrapper() {
            // NebulaPicture component is broken by NebulaImage's logic containing div wrapper.
            // Extract the image node by checking for `nebula-image` class.
            // Replace the div wrapper with the image node.
            const nodes = [...this.tempEl.childNodes];
            let image;

            nodes.forEach((node) => {
                if (node.classList && node.classList.contains('nebula-image')) {
                    image = node;
                }
            });

            this.tempEl.replaceWith(image);
        },
        setImageStatus() {
            this.loading = true;

            setTimeout(() => {
                this.imageExists = true;
                setTimeout(() => {
                    this.loaded = true;
                    if (this.pictureComponent) {
                        this.removeWrapper();
                    }
                }, 200);
            }, 450);
        },
    },
    mounted() {
        if (this.assetId) {
            this.checkImageUrl();
        } else if (!this.assetId && this.pictureComponent) {
            this.removeWrapper();
        }

        // Do not lazy-load images when printing; all images should load.
        window.addEventListener('beforeprint', this.onBeforePrint);
        window.addEventListener('afterprint', this.onAfterPrint);
    },
    beforeUnmount() {
        window.removeEventListener('beforeprint', this.onBeforePrint);
        window.removeEventListener('afterprint', this.onAfterPrint);
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-image-aspect-ratio: initial;
    --nebula-image-background-color: initial;
    --nebula-image-border-block-end: initial;
    --nebula-image-border-block-start: initial;
    --nebula-image-border-inline-end: initial;
    --nebula-image-border-inline-start: initial;
    --nebula-image-border-radius: initial;
    --nebula-image-box-shadow: initial;
    --nebula-image-height: initial;
    --nebula-image-margin: initial;
    --nebula-image-object-fit: initial;
    --nebula-image-object-position: initial;
    --nebula-image-outline: initial;
    --nebula-image-padding: initial;
    --nebula-image-position: initial;
    --nebula-image-rotate: initial;
    --nebula-image-transform: initial;
    --nebula-image-width: initial;
    --nebula-image-z-index: initial;
}

.nebula-image__wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.nebula-image {
    aspect-ratio: var(--nebula-image-aspect-ratio);
    block-size: auto;
    background-color: var(--nebula-image-background-color);
    border-block-end: var(--nebula-image-border-block-end);
    border-block-start: var(--nebula-image-border-block-start);
    border-inline-end: var(--nebula-image-border-inline-end);
    border-inline-start: var(--nebula-image-border-inline-start);
    border-radius: var(--nebula-image-border-radius);
    box-shadow: var(--nebula-image-box-shadow);
    height: var(--nebula-image-height);
    margin: var(--nebula-image-margin);
    max-inline-size: 100%;
    object-fit: var(--nebula-image-object-fit);
    object-position: var(--nebula-image-object-position);
    outline: var(--nebula-image-outline);
    padding: var(--nebula-image-padding);
    position: var(--nebula-image-position);
    rotate: var(--nebula-image-rotate);
    transform: var(--nebula-image-transform);
    width: var(--nebula-image-width);
    z-index: var(--nebula-image-z-index);

    &--contained {
        --nebula-image-object-fit: contain;
    }

    &--covered {
        --nebula-image-object-fit: cover;
    }

    &__icon {
        display: none;
    }
}

</style>
